const TestLists = ({tests}) => {
  return (
    <>
    {tests.map((test)=>(
      <div key={test.id}>
       <div className='row border-bottom' >
            <div className='col-5'>Name</div>
            <div className='col-7'>{test.name}</div>
        </div>
        <div className='row border-bottom'>
            <div className='col-5'>Description</div>
            <div className='col-7'>{test.description}</div>
        </div> 
      </div>    
    ))}
    </>
  )
}

export default TestLists
