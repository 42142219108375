import BackEnd from './BackEnd'
import FrontEnd from './FrontEnd'
import Utility from './Utility'
import AndroidApp from './AndroidApp'
import Navbar from './Navbar'

const Content = () => {
  return (
    <div className='row d-flex justify-content-center'>
      <Navbar/>
      <BackEnd/>
      <FrontEnd/>
      <Utility/>
      <AndroidApp/>
    </div>
  )
}

export default Content
