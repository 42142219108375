import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'

const FrontEnd = () => {
    const params=useParams()
    const [webs, setWebs]=useState([])
    useEffect(()=>{
        const getWeb=async()=>{
            const serverData= await fetchWeb()
            setWebs(serverData)
        }
        getWeb()
    },[]) 
    const fetchWeb=async()=>{
        const res=await fetch('https://march2022.qizhonglabs.com/api-back/web/')
        const fetchedData=res.json()
        return fetchedData
    }
  return (
    <>
    <div className='row'>
        <div className='col-12 text-center pt-5'>
            <h4 id="web" className='font-effect-fire-animation'>A Display of Websites</h4>
        </div>
    </div>
    <div className='row my-5 '>
        { webs.map((web)=>
            <div key={web.id}>
            <div className='row' >
                <div className='col-xs-12 col-sm-6 offset-sm-1 py-5 my-3 bd'>
                    <a href={web.link}> <img src={web.img} alt={web.alt} className='img-fluid'/></a>
                </div>
                <div className='col-xs-12 col-sm-4 my-5'>
                    <div className='row'>
                        <div className='col-12'>
                            <a href={web.link} >{web.title}</a>
                        </div>
                        <div className='col-12'>
                            {web.intro}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )}
    </div>
    {params.home ==="Back" &&
    <div className='row'>
        <div className='col text-center'>
            <Link to='/'><button type='button' className='btn btn-primary' >{params.home}</button></Link>
        </div>
    </div>
    }
    </>
  )
}

export default FrontEnd
