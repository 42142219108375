import '../assets/css/style.css'

const Header = () => {

  return (
    <div className="row py-5 bk-header" id='title'>
        <div className="col-xs-0 col-sm-2"></div>
        <div className="col-xs-6 col-sm-5 text-sm-end text-center" >
            <h1> <strong>QIZHONG LABS</strong></h1>
        </div>
        <div className="col-xs-6 col-sm-5 mt-auto text-sm-start text-center">
            <h5 className=""><strong>Dedicated Developers At Your Service</strong></h5>
        </div>
    </div>
  )
}

export default Header
