import {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'

const Utility = () => {
  const params=useParams()
  const [progs, setProgs]=useState([])
  useEffect(()=>{
    const getUtil=async()=>{
      const serverData=await fetchProgs()
      setProgs(serverData)
    }
    getUtil()
  },[])
  const fetchProgs=async()=>{
    const res=await fetch('https://march2022.qizhonglabs.com/api-back/prog/')
    const fetchedData=res.json()
    return fetchedData
  }
  return (
    <>
    <div className='row my-3'>
      <div className='col text-center'>
        <h4 id="prog" className='font-effect-fire-animation'> Utility Programs</h4>
      </div>
    </div>
    <div className='row'>
      <div className='col-xs-12 col-sm-4 '>
        <div id='div40hz'>
          <p className='text-center pt-3 pb-0 m-0'><strong>40Hz audio/visual stimuli</strong></p>
          <p className='pt-0'>Results from researches and clinical trials suggest that receiving 40Hz 
          audio/video signals daily can slow down the progression of neurological 
          disorders.Please hover over this area to experience the 40Hz light flush 
          and 40Hz stereo sound effects.</p>
        </div>
      </div>
      <div className='col-xs-12 col-sm-8 Py-auto'>
        {progs.map((prog)=>
          <div className='row my-5' key={prog.id}>
            <div className='col-4 text-end'>
              <a href={prog.link}> {prog.name}</a>
            </div>
            <div className='col-8'>
              {prog.intro}
            </div>
          </div>
        )}
      </div>
    </div>
    {params.home === 'Back' && 
    <div className='row'>
        <div className='col text-center'>
            <Link to='/'><button type='button' className='btn btn-primary' >{params.home}</button></Link>
        </div>
    </div>}
    </>
  )
}
export default Utility
