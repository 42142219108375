import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className='row'>
        <div className='col text-center m-5 p-5 text-danger'>
             <h3> The page is not found.</h3>
             <Link to='/'>Please click here to go to the home page.</Link>
        </div>
    </div>
  )
}

export default NotFound
