import { useState } from 'react';
import '../assets/css/style.css';
import {faYoutube, faFacebook, faTwitter, faInstagram} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  const [name, setName]=useState('')
  const [email, setEmail]=useState('')
  const [message, setMessage]=useState('')
  const [sent, setSent]=useState(false)
  const submit=async(e)=>{
    e.preventDefault()
    if(name && email && message){    
      const res=await fetch(
        'https://march2022.qizhonglabs.com/api-back/contact/',
        {
          method:'POST',
          headers:{
            'Content-type':'application/json'
          },
          body:JSON.stringify({contact:{
            name:name, 
            email:email, 
            message:message,
          }}),
        }
      )
      if (res.status === 201){
        setName('')
        setEmail('')
        setMessage('')
        setSent(true)
      }       
    }else{
      alert('Please fill in the form!')
    }
  }
  const form=
    (sent)?(<div className="">Your message was sent. Thank you for contacting us!</div>
      ):(
      <form className="mx-1" onSubmit={submit} >        
          <div className="form-group row ml-0">
              <label className="col-xs-12 col-sm-3 " htmlFor="id_name">Name</label>
              <input className="col-xs-12 col-sm-8" type="text" value={name} id="id_name" onChange={(e)=>setName(e.target.value)}/>
          </div>
          <div className="form-group row">
              <label className="col-xs-12 col-sm-3 " htmlFor="id_email">Email</label>
              <input className="col-xs-12 col-sm-8" type="email" value={email} id="id_email" onChange={(e)=>setEmail(e.target.value)}/>
          </div>
          <div className="form-group row">    
              <label className="col-xs-12 col-sm-3 " htmlFor="id_message">Message</label>
              <textarea className="col-xs-12 col-sm-8" type="text" value={message} id="id_massage" onChange={(e)=>setMessage(e.target.value)}/>
          </div>
          <div className=' d-grid my-3'>
              <button className="btn btn-primary" type="submit">Submit</button>
          </div>
        </form> 
    )
  
  return (
    <div className='row p-5 bk-footer'>
      <div className='col-xs-12 col-sm-4'>
        <h5 className='text-center'>Contact Us</h5>
        {form}
      </div>
      <div className='col-xs-12 col-sm-4 text-center'>
          <h5>Social Media</h5>
          <p><a href="https://www.youtube.com/channel/UCXFEFpcAAVeqcD87w7gYlqw/videos"
            className="youtube social">
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a></p>
          <p><a href="https://www.facebook.com/qi.zhong.31/"
            className="facebook social">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a></p>
          <p><a href="https://www.twitter.com/QIZHONGLabs/" className="twitter social">
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a></p>
          <p><a href="https://www.instagram.com"
            className="instagram social">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a></p>
      </div>
      <div className='col-xs-12 col-sm-4'>
        <h5 className='text-center'>Our Privacy Policies</h5>
        <ul >
        <li >We do not intentionly use cookies</li>
        <li>We never collect our visitors information</li>
        <li>We do not share any information about our visitors with anyone</li>
        </ul>
      </div>
    </div>
  )
}

export default Footer
